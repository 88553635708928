import { differenceInDays, parse } from 'date-fns'
import { z } from 'zod'
import { WhitelistedPropertiesForOperators } from '../constants'

export const emailSchema = z.string({ message: 'Email is required' }).email({
    message: 'Invalid Email address',
})

export const passwordSchema = z
    .string({ message: 'Password is required' })
    .min(8, { message: '8-character minimum length' })
    .max(20, { message: '20-character maximum length' })
    .refine((password) => /[A-Z]/.test(password), {
        message: 'Contains at least 1 uppercase letter',
    })
    .refine((password) => /[a-z]/.test(password), {
        message: 'Contains at least 1 lowercase letter',
    })
    .refine((password) => /[0-9]/.test(password), {
        message: 'Contains at least 1 number',
    })
    .refine((password) => /[!@#$%^&*]/.test(password), {
        message: 'Contains at least 1 special character from the following set',
    })

export const codeSchema = z
    .string()
    .regex(/^[0-9]{6}$/, 'Confirmation Code should be a number of 6 digits')
export const loginSchema = z.object({
    email: emailSchema,
    password: z
        .string({ message: 'Password is required' })
        .min(2, 'Password too short!'),
})
export const resetPasswordSchema = z
    .object({
        password: passwordSchema,
        code: codeSchema,
        email: emailSchema,
        confirmPassword: z.string({
            message: 'Confirm Password is required',
        }),
    })
    .refine((data) => data.confirmPassword === data.password, {
        message: 'Passwords do not match!',
        path: ['confirmPassword'],
    })

const startEndDateValidation = z
    .object({
        from: z.date(),
        to: z.date(),
    })
    .refine(({ from, to }) => differenceInDays(to, from) <= 365, {
        message:
            'Date range cannot exceed 365 days. Please select a new date range.',
    })

export const orderAnalysisFormValidation = z.object({
    startEndDate: startEndDateValidation,
    // endDate: z.date(),
    sortBy: z.string(),
    orderBy: z.enum(['asc', 'desc']),
    selection1: z.string(),
    selection2: z.string().optional(),
    selection3: z.string().optional(),
    selection4: z.string().optional(),
    selectLocation: z.array(z.string()),
    selectBuilding: z.array(z.string()),
    selectUnit: z.array(z.string()),
})

export const placeOrderFilterValidation = z.object({
    shipTo: z.string({ message: 'ShipTo is required' }).min(1),
    unitType: z.string({ message: 'UnitType is required' }).min(1),
})

export type PlaceOrderFilterValidationType = z.infer<
    typeof placeOrderFilterValidation
>

export const placeOrderFormValidation = z.object({
    orderedBy: z
        .string()
        .min(1, { message: 'Ordered By is required' })
        .regex(/^[a-zA-Z0-9\s]+$/, {
            message: 'Ordered By should not contain special characters',
        }),
    email: emailSchema,
    poNo: z.string().min(1, { message: 'P.O. Number is required' }),
    occupied: z.string().min(1, { message: 'Occupied is required' }),
    moveInDate: z.date().optional(),
    anyTime: z.string().optional(),
    requestedInstallDate: z.date({
        message: 'Install date is required',
    }),
    onsiteContact: z.string().optional(),
    onsiteContactInfo: z.string().optional(),
    streetAddress: z.string().optional(),
    buildingNumber: z.string().optional(),
    unitNumber: z.string().min(1, { message: 'Unit Number is required' }),
    generalComment: z
        .string()
        .max(250, {
            message: 'General Comment should not exceed 250 characters',
        })
        .optional(),
    items: z.array(z.string()),
})

export const userAdminSchema = z.object({
    displayName: z.string({ message: 'Display Name is required' }),
    emailAddress: z
        .string({ message: 'Email address is required' })
        .email({ message: 'Invalid email address' }),
    selectLocation: z
        .array(z.string())
        .min(1, { message: 'You must have to select minimum 1 Property' }),
    pageAccessLabelName: z.array(z.string()).min(1, {
        message: 'You must have to select minimum 1 PageAccess',
    }),
    id: z.string().optional(),
})

export type PlaceOrderFormValidation = z.infer<typeof placeOrderFormValidation>

export const filterSchema = z
    .object({
        searchField: z.string(),
        searchText: z.string(),
        sortBy: z.string(),
        status: z.string(),
        operatorField: z.string(),
        selectLocation: z.array(z.string()),
        selectBuilding: z.array(z.string()),
        selectUnit: z.array(z.string()),
        startEndDate: z
            .object({
                from: z.date(),
                to: z.date(),
            })
            .refine(({ from, to }) => differenceInDays(to, from) <= 365, {
                message:
                    'Date range cannot exceed 365 days. Please select a new date range.',
            }),
        sortDirection: z.enum(['-1', '1']).default('1').optional(),
        shipTo: z.string().optional(),
        unitType: z.string().optional(),
    })
    .superRefine((data, ctx) => {
        console.log('superrefine data.searchField', data.searchField)
        if (
            WhitelistedPropertiesForOperators.includes(data.searchField) &&
            !data.searchText.length
        ) {
            ctx.addIssue({
                code: z.ZodIssueCode.custom,
                message: 'Search Text cannot be empty',
                path: ['searchText'],
            })
        }
        if (
            data.searchText.length &&
            ['orderNumber'].includes(data.searchField)
        ) {
            !z
                .string()
                .regex(/^[0-9]*$/)
                .safeParse(data.searchText).success &&
                ctx.addIssue({
                    code: z.ZodIssueCode.custom,
                    message: 'Search Text should be numeric.',
                    path: ['searchText'],
                })
        }
        // when searchfield includes of the listed items in an array, then searchtext should be date in MM/DD/YYYY format
        if (
            data.searchText.length &&
            ['orderDate', 'installDate'].includes(data.searchField)
        ) {
            if (
                parse(data.searchText, 'MM/dd/yyyy', new Date()).toString() ===
                'Invalid Date'
            ) {
                ctx.addIssue({
                    code: z.ZodIssueCode.custom,
                    message: 'Search Text should be in MM/DD/YYYY format.',
                    path: ['searchText'],
                })
            }
        }
    })

export const invoiceFilterSchema = z
    .object({
        searchField: z.string().optional(),
        searchText: z.string().optional(),
        sortBy: z.string().optional(),
        sortDirection: z.enum(['1', '-1']).default('1').optional(), // Negative -> asc
        status: z.string().optional(),
        selectLocation: z.array(z.string()).optional(),
        selectBuilding: z.array(z.string()).optional(),
        selectUnit: z.array(z.string()).optional(),
        startEndDate: z
            .object({
                from: z.date(),
                to: z.date(),
            })
            .refine(({ from, to }) => differenceInDays(to, from) <= 365, {
                message:
                    'Date range cannot exceed 365 days. Please select a new date range.',
            }),
        shipTo: z.string().optional(),
        unitType: z.string().optional(),
    })
    .superRefine((data, ctx) => {
        if (
            WhitelistedPropertiesForOperators.includes(data.searchField) &&
            !data.searchText.length
        ) {
            ctx.addIssue({
                code: z.ZodIssueCode.custom,
                message: 'Search Text cannot be empty',
                path: ['searchText'],
            })
        }
        if (
            data.searchText.length &&
            ['IVC_INVNO', 'IVC_ORDNO', 'R2_CBAL', 'CURRENTBALANCE'].includes(
                data.searchField
            )
        ) {
            !z
                .string()
                .regex(
                    ['R2_CBAL', 'CURRENTBALANCE'].includes(data.searchField)
                        ? /^[0-9]*\.?[0-9]+$/
                        : /^[0-9]*$/
                )
                .safeParse(data.searchText).success &&
                ctx.addIssue({
                    code: z.ZodIssueCode.custom,
                    message: 'Search Text should be numeric.',
                    path: ['searchText'],
                })
        }

        // when searchfield includes of the listed items in an array, then searchtext should be date in MM/DD/YYYY format
        if (
            data.searchText.length &&
            ['orderDate', 'installDate'].includes(data.searchField)
        ) {
            if (
                parse(data.searchText, 'MM/dd/yyyy', new Date()).toString() ===
                'Invalid Date'
            ) {
                ctx.addIssue({
                    code: z.ZodIssueCode.custom,
                    message: 'Search Text should be in MM/DD/YYYY format.',
                    path: ['searchText'],
                })
            }
        }
    })

export const ProrateInvoiceFilterSchema = z.object({
    productServiceLife: z.object({ label: z.string(), value: z.string() }),
    monthsUsed: z.object({ label: z.string(), value: z.string() }),
    percentage: z.string().default('0'),
    selectedRows: z.array(z.number()).default([]).optional(),
    customerName: z
        .string()
        .max(100, { message: 'Customer Name cannot exceed 100 characters' })
        .optional(),
    additionalCharges: z
        .array(
            z
                .object({
                    id: z.number().optional(),
                    style: z.string().optional(),
                    additionalAmount: z.string().regex(/^\d+(\.\d{1,2})?$/, {
                        message:
                            'Invalid Amount. Must be a number with up to 2 decimal places.',
                    }), // should be a number with upto 2 decimal places
                })
                .optional()
        )
        .default([])
        .optional(),

    invoiceProrateData: z
        .array(
            z.object({
                id: z.number().optional(),
                style: z.string(),
                color: z.string(),
                invoiceAmount: z.string(),
                productLife: z.string(),
                monthUsed: z.string(),
                lostLife: z.string(),
                prorateAmount: z.string(),
                type: z.string(),
                buildingUnit: z.string(),
                property: z.string(),
                additionalAmount: z.string().optional(),
                increase: z.string().optional(),
                isSelected: z.boolean().optional(),
                propertyAddress: z.string().optional(),
            })
        )
        .min(1),
})

const MAX_FILE_SIZE = 10000000
const ACCEPTED_IMAGE_TYPES = [
    'image/jpeg',
    'image/jpg',
    'image/png',
    'image/webp',
]

const logoFileValidator = z
    .any()
    .refine((file) => file?.size <= MAX_FILE_SIZE, `Max image size is 5MB.`)
    .refine(
        (file) => ACCEPTED_IMAGE_TYPES.includes(file?.type),
        'Only .jpg, .jpeg, .png and .webp formats are supported.'
    )

const colorValidator = z.string().regex(/^#[0-9A-F]{6}$/i)

export const themeChangerSchemaValidator = z.object({
    location: z.string().optional(),
    salescontactnumber: z.string().optional(),
    supportedemail: z
        .string()
        .email('Invalid email address. Please provide a valid email.')
        .optional(),
    email: z
        .string()
        .email('Invalid email address. Please provide a valid email.')
        .optional(),
    instagram: z.string().url('Invalid URL').optional(),
    facebook: z.string().url('Invalid URL').optional(),
    linkedin: z.string().url('Invalid URL').optional(),
    youtube: z.string().url('Invalid URL').optional(),
    plateformX: z.string().url('Invalid URL').optional(),
    logo: logoFileValidator.optional(),
    primaryColor: colorValidator,
    secondaryColor: colorValidator,
    fontColor: colorValidator,
    bgColor: colorValidator,
    headerFooterColor: colorValidator,
    primaryFont: z.string(),
    secondaryFont: z.string(),
    primaryButtonType: z.string(),
    secondaryButtonType: z.string(),
})

export type themeSchemaValidator = z.infer<typeof themeChangerSchemaValidator>
export type filterSchemaValidation = z.infer<typeof filterSchema>
export type invoiceProrateSchemaValidation = z.infer<
    typeof ProrateInvoiceFilterSchema
>

export type invoiceFilterSchemaValidation = z.infer<typeof invoiceFilterSchema>
