import { AiFillInstagram, AiFillYoutube } from 'react-icons/ai'
import { FaFacebookF, FaLinkedinIn } from 'react-icons/fa'
import { FaXTwitter } from 'react-icons/fa6'
import useFetchCustomTheme from '../pages/theme-customHook'
import { Logo } from './logo'
const Footer = ({
    footerProps,
}: {
    footerProps?: React.HTMLAttributes<HTMLElement>
}) => {
    const currentYear = new Date().getFullYear()
    const { data } = useFetchCustomTheme()
    console.log('footerTextColor', footerProps?.style)
    return (
        <>
            <footer
                className={`text-center w-full bottom-0 bg-willow-ash py-3 pb-2 md:px-12 px-4 custom-header-footer`}
                {...footerProps}
            >
                <div className="grid grid-cols-3">
                    <div className="col-span-2">
                        <div className="">
                            <div className="mb-2 max-w-40">
                                <Logo width={175} />
                            </div>

                            <div className="text-sm sm:text-left mb-2 opacity-70">
                                @Copyright {currentYear}. BroadlumeBMS. All
                                rights reserved.
                            </div>

                            <div className="grid lg:grid-cols-4 md:grid-cols-2 w-full mb-2 gap-2  text-sm">
                                <div className="md:text-sm text-xs">
                                    <span className="text-willow-gold">
                                        Location:{' '}
                                    </span>
                                    <a href="#">
                                        {data?.location || 'Hammond, LA, USA'}
                                    </a>
                                </div>
                                <div className="md:text-sm text-xs">
                                    <span className="text-willow-gold">
                                        Sales:{' '}
                                    </span>
                                    <a href="#">
                                        {data?.salescontactnumber ||
                                            '+1 (850) 303-1756'}
                                    </a>
                                </div>
                                <div className="md:text-sm text-xs">
                                    <span className="text-willow-gold">
                                        Support:{' '}
                                    </span>
                                    <a href="#">
                                        {data?.supportedemail ||
                                            'support@rmaster.com'}
                                    </a>
                                </div>
                                <div className="md:text-sm text-xs ">
                                    <span className="text-willow-gold">
                                        More Info:{' '}
                                    </span>
                                    <a href="#">
                                        {data?.email || 'info@rmaster.com'}
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="justify-end grid grid-rows grid-flow-col md:gap-4 gap-2 content-center">
                        {data?.instagram && (
                            <a
                                href={`https://${data?.instagram}`}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <AiFillInstagram className="text-[hsl(var(--secondary))] w-6 h-6" />
                            </a>
                        )}
                        {data?.plateformX && (
                            <a
                                href={`https://${data?.plateformX}`}
                                target="_blank"
                            >
                                <FaXTwitter className="text-[hsl(var(--secondary))] w-6 h-6" />
                            </a>
                        )}
                        {data?.facebook && (
                            <a
                                href={`https://${data?.facebook}`}
                                target="_blank"
                            >
                                <FaFacebookF className="text-[hsl(var(--secondary))] w-6 h-6" />
                            </a>
                        )}
                        {data?.linkedin && (
                            <a
                                href={`https://${data?.linkedin}`}
                                target="_blank"
                            >
                                <FaLinkedinIn className="text-[hsl(var(--secondary))] w-6 h-6" />
                            </a>
                        )}
                        {data?.youtube && (
                            <a
                                href={`https://${data?.youtube}`}
                                target="_blank"
                            >
                                <AiFillYoutube className="text-[hsl(var(--secondary))] w-6 h-6" />
                            </a>
                        )}
                    </div>
                </div>
            </footer>
        </>
    )
}

export default Footer
