import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuItem,
    DropdownMenuTrigger,
} from '@broadlume/willow-ui'
import { FaChevronDown } from 'react-icons/fa'
import { getRouteApi, useNavigate } from '@tanstack/react-router'
import { PrimaryButton } from './primary-button'
import { handleTabShow } from '../services/auth'
import { useAuthHook } from '../utils/hooks/order-analysis.hook'
import { Logo } from './logo'
const router = getRouteApi('/$clientid/_app')
const Header = ({
    isYardiSource,
    isPreview = false,
    logoUrl,
    ...rest
}: {
    isYardiSource?: boolean
    isPreview?: boolean
    logoUrl?: string
} & React.HTMLAttributes<HTMLDivElement>) => {
    const navigate = useNavigate({ from: '/' })
    const params = router.useParams()
    const { data: userData } = useAuthHook()
    const handleLogout = () => {
        localStorage.clear()
        navigate({ to: '/$clientid/login', params: params })
    }
    return (
        <div
            className="bg-willow-ash text-willow-wool-light top-0 custom-header-footer"
            {...rest}
        >
            <div className="flex flex-row justify-between align-middle pl-12 pr-12 pt-4 pb-4">
                <div className="flex justify-center max-w-40">
                    <Logo
                        logoUrl={logoUrl}
                        onClick={async () => {
                            if (isPreview) {
                                return
                            }
                            if (!isYardiSource) {
                                await navigate({
                                    to: '/$clientid',
                                    params: params,
                                })
                                location.reload()
                            }
                        }}
                    />
                </div>

                <div className="flex flex-row gap-2 items-center">
                    {!isYardiSource &&
                    handleTabShow({
                        userDetails: userData?.userDetails,
                        module: 'write:orders',
                    }) ? (
                        <PrimaryButton
                            title="+ Place Order"
                            onClick={() =>
                                !isPreview &&
                                navigate({ to: '/$clientid/place-order' })
                            }
                        />
                    ) : null}

                    {!isPreview && (
                        <DropdownMenu>
                            <DropdownMenuTrigger className="flex flex-row items-center gap-2">
                                <div className="bg-[hsl(var(--secondary))] text-willow-ash px-2 py-1.5 rounded-full">
                                    {userData?.userDetails?.DisplayName?.substring(
                                        0,
                                        2
                                    ).toUpperCase() ||
                                        userData?.userDetails?.Email?.substring(
                                            0,
                                            2
                                        ).toUpperCase()}
                                </div>
                                <FaChevronDown size={14} />
                            </DropdownMenuTrigger>
                            <DropdownMenuContent>
                                <DropdownMenuItem onClick={handleLogout}>
                                    Logout
                                </DropdownMenuItem>
                            </DropdownMenuContent>
                        </DropdownMenu>
                    )}
                </div>
            </div>
        </div>
    )
}

export default Header
