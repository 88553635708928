import { useQuery } from '@tanstack/react-query'
import { GetPOrdFileAPIResopnse } from '../interfaces/place-order.interface'
import { useAuthHook } from './order-analysis.hook'
import { getPordFile } from '../../services/placeOrder'

export const useGetPordFile = () => {
    const { data: userData } = useAuthHook()
    return useQuery<GetPOrdFileAPIResopnse>({
        queryKey: ['getPordFile', userData],
        queryFn: getPordFile,
        staleTime: Infinity,
        enabled: false,
    })
}
