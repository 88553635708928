import { Button, ButtonProps } from '@broadlume/willow-ui'
import clsx from 'classnames'
import LoadingComponent from './LoadingComponent'

const PrimaryButton: React.FC<
    ButtonProps &
        React.RefAttributes<HTMLButtonElement> & { isLoading?: boolean }
> = ({ title, isLoading = false, children, className = '', ...rest }) => {
    return (
        <Button
            {...rest}
            data-isloading={isLoading}
            className={clsx(
                'text-xs',
                rest.disabled ? 'opacity-60' : '',
                'hover:opacity-85',
                'custom-primary-button custom-primary-font',
                className || ''
            )}
            // style={{
            //     color: getReadableFontColor(data?.bgColor, data?.fontColor),
            // }}
        >
            {isLoading && <LoadingComponent classNames="w-4 h-4 mr-2" />}
            {title ?? children}
        </Button>
    )
}

export { PrimaryButton }
