import clsx from 'classnames'
type Props = { title: string; className?: string } & {
    otherProps?: {
        wrapperProps?: React.HTMLAttributes<HTMLDivElement>
        titleProps?: React.HTMLAttributes<HTMLHeadingElement>
    }
}
/**
 * A functional component that wraps its children within a styled container.
 *
 * @param {React.PropsWithChildren<Props>} props - The props containing children elements to be rendered inside the wrapper.
 * @returns {JSX.Element} A JSX element containing a header and the provided children.
 */
export const PageWrapper = ({
    title,
    children,
    className = '',
    otherProps = { titleProps: {}, wrapperProps: {} },
}: React.PropsWithChildren<Props>) => {
    return (
        <div
            className={clsx(
                'flex flex-col items-start justify-start flex-1 w-4/5 py-6 px-14',
                className
            )}
            {...otherProps.wrapperProps}
        >
            {title && (
                <div className="grid grid-cols-1">
                    <h2
                        className="text-wool md:text-4xl text-xl font-bold mb-6 custom-font-color"
                        {...otherProps.titleProps}
                    >
                        {title}
                    </h2>
                </div>
            )}
            {children}
        </div>
    )
}
