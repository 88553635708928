import { Tabs, TabsList, TabsTrigger } from '@broadlume/willow-ui'
import {
    getRouteApi,
    Outlet,
    useNavigate,
    useRouterState,
} from '@tanstack/react-router'

import { useMemo } from 'react'
import Header from '../components/header'
import { useAuthHook } from '../utils/hooks/order-analysis.hook'
import { getTabs } from '../services/auth'
import { FaUserGroup } from 'react-icons/fa6'
import { FaShoppingCart } from 'react-icons/fa'
import { MdPieChart } from 'react-icons/md'
import { FaFileAlt } from 'react-icons/fa'
import { IoMdSettings } from 'react-icons/io'
import { PiSpeedometerFill } from 'react-icons/pi'

const router = getRouteApi('/$clientid/_app')

export const LandingPage = () => {
    const navigate = useNavigate()
    const params = router.useParams()
    const routerState = useRouterState()
    const { data: userData } = useAuthHook()
    const search = router.useSearch()
    const yardiSource = useMemo(
        () => search?.source === 'yardi',
        [search?.source]
    )

    const tabList = getTabs({ userDetails: userData?.userDetails })

    const iconMapper = {
        dashboard: ({ isActive }) => (
            <PiSpeedometerFill
                className={`w-5 h-5 ${isActive ? 'text-[hsl(var(--primary))]' : ''}`}
            />
        ),
        orders: ({ isActive }) => (
            <FaShoppingCart
                className={`w-5 h-5 ${isActive ? 'text-[hsl(var(--primary))]' : ''}`}
            />
        ),
        'order-analysis': ({ isActive }) => (
            <MdPieChart
                className={`w-5 h-5 ${isActive ? 'text-[hsl(var(--primary))]' : ''}`}
            />
        ),
        invoices: ({ isActive }) => (
            <FaFileAlt
                className={`w-5 h-5 ${isActive ? 'text-[hsl(var(--primary))]' : ''}`}
            />
        ),
        'user-admin': ({ isActive }) => (
            <FaUserGroup
                className={`w-5 h-5 ${isActive ? 'text-[hsl(var(--primary))]' : ''}`}
            />
        ),
        'theme-changer': ({ isActive }) => (
            <IoMdSettings
                className={`w-5 h-5 ${isActive ? 'text-[hsl(var(--primary))]' : ''}`}
            />
        ),
    }

    return (
        <div className="flex flex-col flex-1">
            <Header isYardiSource={yardiSource} />
            {!yardiSource ? (
                <div className="flex justify-center shadow-[0px_8px_16px_0px_#0000000D] bg-[transparent]">
                    <Tabs
                        className="w-9/12 ~border-none"
                        defaultValue={routerState.location.pathname}
                    >
                        <TabsList className="~bg-transparent py-5 flex flex-row items-center justify-center gap-7">
                            {tabList.map(
                                (eachTab) =>
                                    eachTab.isTab && (
                                        <TabsTrigger
                                            key={eachTab.id}
                                            id={eachTab.id}
                                            value={`/${params.clientid}/${eachTab.id}`}
                                            onClick={() =>
                                                navigate({
                                                    to:
                                                        '/$clientid/' +
                                                        eachTab.id,
                                                    params,
                                                })
                                            }
                                            className="border-none flex align-middle justify-between gap-2"
                                        >
                                            {iconMapper?.[eachTab.id]({
                                                isActive:
                                                    routerState.location
                                                        .pathname ===
                                                    `/${params.clientid}/${eachTab.id}`,
                                            })}
                                            <span>{eachTab.label}</span>
                                        </TabsTrigger>
                                    )
                            )}
                        </TabsList>
                    </Tabs>
                </div>
            ) : null}

            <div className="flex items-center justify-center">
                <Outlet />
            </div>
        </div>
    )
}
