import {
    Card,
    CardContent,
    CardFooter,
    CardHeader,
    CardTitle,
} from '@broadlume/willow-ui'
import { useForm } from '@tanstack/react-form'
import { useMutation } from '@tanstack/react-query'
import { getRouteApi, useNavigate, useRouter } from '@tanstack/react-router'
import { zodValidator } from '@tanstack/zod-form-adapter'
import { CustomInput } from '../components/custom-input'
import { PrimaryButton } from '../components/primary-button'
import { useCustomToast } from '../components/useCustomToast'
import { signIn } from '../services/auth'
import { loginSchema } from '../utils/validators/schemas'
import { OnboardingContainer } from './onboarding'
import { useGetClient } from '../utils/hooks/order-analysis.hook'
const router = getRouteApi('/$clientid/_onboarding/login')

const Login = () => {
    const params = router.useParams()
    const router1 = useRouter()
    const { data } = useGetClient()
    console.log('login clientid', data)
    const navigate = useNavigate({ from: '/' })
    const { toast } = useCustomToast()
    const loginMutation = useMutation({
        mutationKey: ['login'],
        mutationFn: signIn,
    })
    const form = useForm({
        defaultValues: {
            email: '',
            password: '',
        },
        validators: {
            onChange: loginSchema,
            onMount: loginSchema,
        },
        validatorAdapter: zodValidator(),
        onSubmit: async ({ value }) => {
            try {
                await loginMutation.mutateAsync({
                    email: value.email,
                    password: value.password,
                    clientId: params.clientid,
                })
                toast({
                    title: 'Login Successful',
                    description: 'Redirecting to Dashboard',
                })
                router1.invalidate()
            } catch (error) {
                // setValmsg(true)
                console.log('error login===', error)
                if (error?.status === 301 && error?.response?.data?.redirect) {
                    toast({
                        title: 'Password Expired!',
                        description:
                            'Please reset the password using the code sent on your email.',
                    })
                    navigate({
                        to: '/$clientid/resetpassword',
                        params,
                        search: {
                            email: value.email,
                        },
                    })
                } else {
                    toast({
                        title: 'Invalid Request',
                        description:
                            error?.response?.data?.message ?? error?.message,
                    })
                }
            }
        },
    })

    const emailField = form.useField({
        name: 'email',
    })

    const passwordField = form.useField({
        name: 'password',
    })

    return (
        <OnboardingContainer>
            <Card className="pt-6 w-2/5 sm:w-4/5 md:w-1/2 lg:w-1/3 rounded-2xl border-none shadow-[0px_15px_50px_0px_#0000001A] p-2 m-auto">
                <form>
                    <CardHeader className="md:p-0 p-1">
                        <CardTitle className="text-center text-2xl font-bold  not-italic font-sans md:mb-0 mb-0">
                            Login
                        </CardTitle>
                    </CardHeader>
                    <CardContent className="pb-0 md:p-1 px-1">
                        <CustomInput
                            id={emailField.name}
                            name={emailField.name}
                            value={emailField.state.value}
                            onChange={(e) =>
                                emailField.setValue(e.target.value)
                            }
                            isTouched={emailField.state.meta.isTouched}
                            errors={emailField.state.meta.errors}
                            onBlur={emailField.handleBlur}
                            label={'Email'}
                            type={'email'}
                            classNames={{
                                root: ['my-4'],
                            }}
                        />
                        <CustomInput
                            id={passwordField.name}
                            name={passwordField.name}
                            value={passwordField.state.value}
                            onChange={(e) =>
                                passwordField.setValue(e.target.value)
                            }
                            isTouched={passwordField.state.meta.isTouched}
                            errors={passwordField.state.meta.errors}
                            onBlur={passwordField.handleBlur}
                            label={'Password'}
                            type={'password'}
                            classNames={{
                                root: ['my-4'],
                            }}
                        />
                    </CardContent>
                    <CardFooter className="grid grid-cols-1 pb-3">
                        <span
                            className="text-right cursor-pointer md:text-base text-sm font-sans font-bold text-[#008FBD] sm:my-1 my-2 cursor justify-self-end w-max"
                            onClick={() =>
                                navigate({ to: '/$clientid/forgotpassword' })
                            }
                            tabIndex={0}
                            onKeyDown={(e) => {
                                if (['Enter', ' '].includes(e.key)) {
                                    navigate({
                                        to: '/$clientid/forgotpassword',
                                    })
                                }
                            }}
                        >
                            Forgot Password?
                        </span>

                        <PrimaryButton
                            type="submit"
                            data-testid="submit"
                            title="Login"
                            onClick={form.handleSubmit}
                            className="text-center h-6 md:mt-1 mt-2 py-5 w-fit px-12 justify-self-center md:text-base text-sm"
                            isLoading={form.state.isSubmitting}
                            disabled={
                                !(
                                    !form.state.isPristine && form.state.isValid
                                ) || form.state.isSubmitting
                            }
                        />
                    </CardFooter>
                </form>
            </Card>
        </OnboardingContainer>
    )
}

export default Login
