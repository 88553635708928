import { useQuery } from '@tanstack/react-query'
import { useGetClient } from '../utils/hooks/order-analysis.hook'
import { themeSchemaValidator } from '../utils/validators/schemas'
const useFetchCustomTheme = () => {
    const { data: clientId } = useGetClient()
    const { data, isLoading } = useQuery({
        queryKey: ['theme', 'userData', clientId],
        enabled: Boolean(clientId),

        queryFn: async () => {
            try {
                const response = await fetch(
                    `https://dev.customerportal.bms.my.broadlume.com/${clientId}/data/custom-theme.json`
                )
                return (await response.json()) as themeSchemaValidator
            } catch (error) {
                console.error('error themeing', error)
                return {}
            }
        },
        staleTime: Infinity,
    })
    return { data, isLoading }
}

export default useFetchCustomTheme
