import {
    Checkbox,
    Label,
    Tooltip,
    TooltipContent,
    TooltipProvider,
    TooltipTrigger,
} from '@broadlume/willow-ui'

const PASSWORD_ERROR_LIST = [
    '8-character minimum length',
    'Contains at least 1 number',
    'Contains at least 1 lowercase letter',
    'Contains at least 1 uppercase letter',
    'Contains at least 1 special character from the following set',
]
export const PasswordHelperComponent = ({
    errors,
    showPasswordPopup,
}: {
    errors: string
    showPasswordPopup: boolean
}) => {
    const errorList = (errors && (errors[0] as string))?.split(',') || []
    if (!errorList.length) {
        return null
    }
    return (
        <TooltipProvider>
            <Tooltip open={showPasswordPopup}>
                <TooltipTrigger />

                <TooltipContent
                    side="right"
                    className=" bg-willow-wool-light rounded-lg p-5 shadow-2xl"
                >
                    <p className="mb-4 font-semibold">Requirements: </p>
                    {errorList.length
                        ? PASSWORD_ERROR_LIST.map((error, index) => {
                              const foundError = errorList.findIndex(
                                  (err) => err.trim() === error
                              )
                              return (
                                  <div className="text-sm mb-3 flex items-center gap-2">
                                      <Checkbox
                                          className="data-[state=checked]:bg-[#4BB543] border-willow-ash-lighter cursor-default"
                                          onClick={(e) => e.stopPropagation()}
                                          checked={foundError === -1}
                                          id={'pass-err-' + index}
                                      />
                                      <Label htmlFor={'pass-err-' + index}>
                                          {' '}
                                          {error}
                                      </Label>
                                  </div>
                              )
                          })
                        : null}
                    <div className="text-sm mt-1 block font-bold">
                        <span className="text-willow-danger">*</span> ! @ # $ %
                        ^ & *
                    </div>
                </TooltipContent>
            </Tooltip>
        </TooltipProvider>
    )
}
