import { useGetClient } from '../utils/hooks/order-analysis.hook'

type ImageWrapperProps = React.ImgHTMLAttributes<HTMLImageElement> & {
    logoUrl?: string
}

export const Logo = (props: ImageWrapperProps) => {
    const { data: clientId } = useGetClient()
    const CLIENT_CUSTOM_LOGO =
        props?.logoUrl ??
        `https://dev.customerportal.bms.my.broadlume.com/${clientId}/data/images/logo.png`
    const handleFallbackError = (e) => {
        e.target.src = '/broadlume-logo.png' // Set fallback image
        e.target.onerror = null // Prevent infinite loop
        e.target.className = 'w-60 h-7'
    }
    return (
        <img
            className="w-full h-auto aspect-auto"
            src={CLIENT_CUSTOM_LOGO}
            onError={handleFallbackError}
            alt="Company Logo"
            {...props}
        />
    )
}
