import { CustomDataTable } from '@broadlume/willow-ui'
import { sub } from 'date-fns'
import { SelectWrapper } from '../components/SelectWrapper'
import { InputField } from './interfaces/orders.interface'
import {
    filterSchemaValidation,
    invoiceProrateSchemaValidation,
} from './validators/schemas'
import { ProductServiceLifeType } from '../pages/invoices/ProrateInvoice'
import { InvoiceProrateType } from './interfaces/place-order.interface'

export const searchFieldMap: Map<string, string> = new Map([
    ['IVC_INVNO', 'invoiceNumber'],
    ['IVC_ORDNO', 'invoiceOrderNumber'],
    ['IVC_CUSTPO', 'poNumber'],
    ['DMH_SHADDR1', 'address1'],
    ['BLDG_ONLY', 'building'],
    ['UNIT_ONLY', 'unit'],
    ['CURRENTBALANCE', 'amount'],
    ['R2_CBAL', 'balanceDue'],
])

export const sortByFields: Parameters<typeof SelectWrapper>[0]['data'] = [
    {
        value: 'SLS_SALEAMT',
        id: 'saleAmount',
        label: 'Sale Amount',
    },
    {
        value: 'SLS_SALEQTY',
        id: 'saleQuantity',
        label: 'Sale Quantity',
    },
]

export const orderByFields: Parameters<typeof SelectWrapper>[0]['data'] = [
    {
        value: 'desc',
        id: 'desc',
        label: 'Descending',
    },
    {
        value: 'asc',
        id: 'asc',
        label: 'Ascending',
    },
]

export const selectionFields: Parameters<typeof SelectWrapper>[0]['data'] = [
    {
        value: 'SLS_INVDATE',
        id: 'Invoice Date',
        label: 'Invoice Date',
    },
    {
        value: 'SLS_STYLE',
        id: 'Style',
        label: 'Style',
    },
    {
        value: 'SLS_ORDNO',
        id: 'Job Number',
        label: 'Job Number',
    },
    {
        value: 'SLS_SAL1',
        id: 'Sales Person',
        label: 'Sales Person',
    },
    {
        value: 'SLS_INVNO',
        id: 'Invoice Number',
        label: 'Invoice Number',
    },
    {
        value: 'DMH_SHADDR1',
        id: 'Address',
        label: 'Address',
    },
    {
        value: 'PC_TYPE',
        id: 'Product Type',
        label: 'Product Type',
    },
    {
        value: 'DMI_COLOR',
        id: 'Color',
        label: 'Color',
    },
    {
        value: 'BLDG_ONLY',
        id: 'Building',
        label: 'Building',
    },
    {
        value: 'UNIT_ONLY',
        id: 'Unit',
        label: 'Unit',
    },
    {
        value: 'DMI_SHIPTO',
        id: 'Job Address',
        label: 'Job Address',
    },
    {
        value: 'DMI_SOLDTO',
        id: 'Location',
        label: 'Location',
    },
    {
        value: 'SLS_CUSTID',
        id: 'Customer Name',
        label: 'Customer Name',
    },
]

export const unitData = [
    {
        id: 'selectAllUnit',
        isChecked: true,
        label: 'Select All',
        name: 'selectAll',
    },
    {
        id: '1',
        name: 'Burlon - chris burlon (7653 cedar Park LN)',
        label: 'Burlon - chris burlon (7653 cedar Park LN)',
        isChecked: true,
    },
    {
        id: '2',
        name: 'Burlon - chris burlon (7653 cedar Park LN)',
        label: 'Burlon - chris burlon (7653 cedar Park LN)',
        isChecked: true,
    },
    {
        id: '3',
        name: 'Burlon - chris burlon (7653 cedar Park LN)',
        label: 'Burlon - chris burlon (7653 cedar Park LN)',
        isChecked: true,
    },
]

export const locationData = [
    {
        id: 'selectAllLocation',
        isChecked: true,
        label: 'Select All',
        name: 'selectAll',
    },
    {
        id: '12',
        name: 'Burlon - chris burlon (7653 cedar Park LN)',
        label: 'Burlon - chris burlon (7653 cedar Park LN)',
        isChecked: true,
    },
    {
        id: '23',
        name: 'Burlon - chris burlon (7653 cedar Park LN)',
        label: 'Burlon - chris burlon (7653 cedar Park LN)',
        isChecked: true,
    },
    {
        id: '34',
        name: 'Burlon - chris burlon (7653 cedar Park LN)',
        label: 'Burlon - chris burlon (7653 cedar Park LN)',
        isChecked: true,
    },
]

export const buildingData = [
    {
        id: 'selectAllBuilding',
        isChecked: true,
        label: 'Select All',
        name: 'selectAll',
    },
    {
        id: '11',
        name: 'Burlon - chris burlon (7653 cedar Park LN)',
        label: 'Burlon - chris burlon (7653 cedar Park LN)',
        isChecked: true,
    },
    {
        id: '22',
        name: 'Burlon - chris burlon (7653 cedar Park LN)',
        label: 'Burlon - chris burlon (7653 cedar Park LN)',
        isChecked: true,
    },
    {
        id: '33',
        name: 'Burlon - chris burlon (7653 cedar Park LN)',
        label: 'Burlon - chris burlon (7653 cedar Park LN)',
        isChecked: true,
    },
]

export const checkBoxStyling = {
    root: ['mr-2'],
    mainLabel: ['!mb-2 text-sm font-light'],
    checkboxLabel: ['!text-sm font-light my-0.5 !mx-0'],
    checkbox: ['data-[state=checked]:!bg-willow-ribbon !text-base'],
}

export const orderAnalysisFormDefaultValues = {
    startEndDate: {
        from: sub(new Date(), {
            days: 365,
        }),
        to: new Date(),
    },
    // startEndDate: {
    //     from: sub(new Date('2023-12-02'), {
    //         days: 365,
    //     }),
    //     to: new Date('2023-12-02'),
    // },
    sortBy: 'SLS_SALEQTY',
    orderBy: 'desc' as const,
    selection1: undefined,
    selection2: undefined,
    selection3: undefined,
    selection4: undefined,
    selectLocation: [] as string[], //'MANDALAY,0000', 'SURFACES,0000'
    selectBuilding: [] as string[],
    selectUnit: [] as string[], //'5698', '12', '5645'
}

export const InvoiceFormDefaultValues = {
    searchField: 'DMH_SHADDR1',
    searchText: '',
    sortBy: 'invoiceDate',
    sortDirection: '1',
    type: 'invoice', //TODO: revert
    status: 'all',
    operatorField: 'equals',
    shipTo: '',
    unitType: '',
    selectLocation: [],
    selectBuilding: [],
    selectUnit: [],
    startEndDate: {
        from: sub(new Date(), {
            days: 365,
        }),
        to: new Date(),
    },
    // startEndDate: {
    //     // TODO: revert
    //     from: sub(new Date('2023-12-02'), {
    //         days: 365,
    //     }),
    //     to: new Date('2023-12-02'),
    // },
}

export const invoiceProrateDefaultValues: Omit<
    invoiceProrateSchemaValidation,
    'type'
> = {
    productServiceLife: undefined,
    invoiceProrateData: [],
    customerName: '',
    monthsUsed: undefined,
    percentage: '0',
    selectedRows: [],
}

export const OrdersDefaultValues: Omit<filterSchemaValidation, 'type'> = {
    searchField: 'customerName',
    searchText: '',
    sortBy: 'orderNumber',
    sortDirection: '1' as '-1' | '1',
    status: 'installed',
    selectLocation: [],
    selectBuilding: [],
    selectUnit: [],
    operatorField: 'equals',
    startEndDate: {
        from: sub(new Date(), {
            days: 365,
        }),
        to: new Date(),
    },
    // startEndDate: {
    //     from: sub(new Date('2023-12-02'), {
    //         days: 365,
    //     }),
    //     to: new Date('2023-12-02'),
    // },
}

export const tableCustomStyle: Parameters<
    typeof CustomDataTable
>[0]['customStyles'] = {
    rows: {
        style: {
            paddingTop: '5px',
            paddingBottom: '5px',
            cursor: 'pointer',
            backgroundColor: '#FFFFFF',
            '&:nth-child(2n)': {
                backgroundColor: '#FAFAFA',
            },
        },
    },
    headRow: {
        style: {
            height: 'auto',
            minHeight: 'auto',
            backgroundColor: '#FFD990',
        },
    },
    headCells: {
        style: {
            height: '40px',
            backgroundColor: '#FFD990',
            fontSize: '13px',
            fontWeight: 400,
            textTransform: 'capitalize',
        },
    },
    cells: {
        style: {
            paddingLeft: '8px', // override the cell padding for data cells
            paddingRight: '8px',
            fontFamily: 'moret,serif !importan',
        },
    },
}

export const orderAnalysisTableLegends = [
    {
        dataFrom: 'keys',
        anchor: 'bottom-right',
        direction: 'column',
        justify: false,
        translateX: 120,
        translateY: 0,
        itemsSpacing: 2,
        itemWidth: 100,
        itemHeight: 20,
        itemDirection: 'left-to-right',
        itemOpacity: 0.85,
        symbolSize: 20,
        effects: [
            {
                on: 'hover',
                style: {
                    itemOpacity: 1,
                },
            },
        ],
    },
]

export const OrderDetailsMapper = {
    orderDetailsLeftSideMapper: [
        {
            titleKey: 'Order Number',
            valueKey: 'DMO_ORDNO',
        },
        {
            titleKey: 'Order Date',
            valueKey: 'DMH_DATE',
        },
        {
            titleKey: 'Sales Rep',
            valueKey: 'SAL_NAME',
        },
        {
            titleKey: 'Install Date',
            valueKey: 'DMH_INSTALLDATE',
        },
        {
            titleKey: 'Install Time',
            valueKey: 'DMH_INSTALL_TIME',
        },
        {
            titleKey: 'Job Status',
            valueKey: 'DMH_STATUSCODE',
        },
    ],
    orderDetailsRightSideMapper: [
        {
            titleKey: 'Quote Number',
            valueKey: '',
        },
        {
            titleKey: 'Building',
            valueKey: 'DMH_BLDG',
        },
        {
            titleKey: 'PO Number',
            valueKey: 'DMH_CUSTPO',
        },
        {
            titleKey: 'Special Instructions',
            valueKey: 'DMH_SPECIAL1',
        },
        {
            titleKey: 'Order By',
            valueKey: 'DMH_ORDEREDBY',
        },
    ],
}

export const OrderDetailsHeaderMapper = {
    billTo: [
        {
            titleKey: 'Contact',
            valueKey: 'DMH_SHCONTACT',
        },
        {
            titleKey: 'Zip',
            valueKey: 'DMH_SHZIP',
        },
        {
            titleKey: 'Address',
            valueKey: 'DMH_SHADDR1',
        },
        {
            titleKey: 'Phone 1',
            valueKey: 'DMH_SHPHONE1',
        },
        {
            titleKey: 'Building',
            valueKey: 'DMH_BLDG',
        },
        {
            titleKey: 'Phone 2',
            valueKey: '',
        },
        {
            titleKey: 'City / State',
            valueKey: 'DMH_SHCTYST',
        },
        {
            titleKey: 'Fax',
            valueKey: 'DMH_SHFAX',
        },
    ],
    shipTo: [
        {
            titleKey: 'Location',
            valueKey: 'DMH_SHNAME',
        },
        {
            titleKey: 'Zip',
            valueKey: 'DMH_SHZIP',
        },
        {
            titleKey: 'Contact',
            valueKey: 'DMH_SHCONTACT',
        },
        {
            titleKey: 'Phone 1',
            valueKey: 'DMH_SHPHONE1',
        },
        {
            titleKey: 'Address',
            valueKey: 'DMH_SHADDR1',
        },
        {
            titleKey: 'Phone 2',
            valueKey: '',
        },
        {
            titleKey: 'Building',
            valueKey: 'DMH_BLDG',
        },
        {
            titleKey: 'Fax',
            valueKey: 'DMH_SHFAX',
        },
        {
            titleKey: 'City / State',
            valueKey: 'DMH_SHCTYST',
        },
        {
            titleKey: 'Property Type',
            valueKey: 'DMH_SHCODE',
        },
    ],
}

export const PropertyRegex = /[a-zA-Z0-9]{1,}\/\*\\[0-9]{1,}\|\|/gm

export const sortByDefaultValues = [
    {
        id: '1',
        label: 'PO Number',
        value: 'poNumber',
    },
    {
        id: '2',
        label: 'Order Date',
        value: 'orderDate',
    },
    {
        id: '3',
        label: 'Install Date',
        value: 'installDate',
    },
    {
        id: '4',
        label: 'Customer Name',
        value: 'customerName',
    },
    {
        id: '5',
        label: 'Location Code',
        value: 'shipToAddress',
    },
    {
        id: '6',
        label: 'Order Number',
        value: 'orderNumber',
    },
    {
        id: '7',
        label: 'Building',
        value: 'building',
    },
    {
        id: '8',
        label: 'Unit',
        value: 'unit',
    },
    {
        id: '9',
        label: 'Ordered By',
        value: 'orderedBy',
    },
] as InputField[]

export const invoicesSortByDefaultValues = [
    {
        id: '1',
        label: 'Invoice Number',
        value: 'invoiceNumber',
    },
    {
        id: '2',
        label: 'Invoice Date',
        value: 'invoiceDate',
    },
    {
        id: '3',
        label: 'Due Date',
        value: 'dueDate',
    },
    {
        id: '4',
        label: 'PO Number',
        value: 'poNumber',
    },
    {
        id: '5',
        label: 'Building',
        value: 'building',
    },
    {
        id: '6',
        label: 'Unit',
        value: 'unit',
    },
    {
        id: '7',
        label: 'Job Number',
        value: 'invoiceOrderNumber',
    },
    {
        id: '8',
        label: 'Ordered By',
        value: 'orderedBy',
    },

    {
        id: '9',
        label: 'Amount',
        value: 'amount',
    },

    {
        id: '10',
        label: 'Balance Due',
        value: 'balanceDue',
    },
] as InputField[]

export const ordersSearchFieldDefaultValues = [
    {
        id: '1',
        label: 'PO Number',
        value: 'poNumber',
    },
    {
        id: '2',
        label: 'Order Date',
        value: 'orderDate',
    },
    {
        id: '3',
        label: 'Install Date',
        value: 'installDate',
    },
    {
        id: '4',
        label: 'Customer Name',
        value: 'customerName',
    },
    {
        id: '5',
        label: 'Location Code',
        value: 'shipToAddress',
    },
    {
        id: '6',
        label: 'Order Number',
        value: 'orderNumber',
    },
    {
        id: '7',
        label: 'Building',
        value: 'building',
    },
    {
        id: '8',
        label: 'Unit',
        value: 'unit',
    },
] as InputField[]

export const invoicesSearchFieldDefaultValues = [
    {
        id: 'address1',
        label: 'Address 1',
        value: 'DMH_SHADDR1',
    },
    {
        id: 'building',
        label: 'Building',
        value: 'BLDG_ONLY',
    },
    {
        id: 'unit',
        label: 'Unit',
        value: 'UNIT_ONLY',
    },
    {
        id: 'invoiceNumber',
        label: 'Invoice Number',
        value: 'IVC_INVNO',
    },
    {
        id: 'invoiceOrderNumber',
        label: 'Job Number',
        value: 'IVC_ORDNO',
    },
    {
        id: 'poNumber',
        label: 'PO Number',
        value: 'IVC_CUSTPO',
    },
    {
        id: 'balanceDue',
        label: 'Current Balance',
        value: 'R2_CBAL',
    },
    {
        id: 'amount',
        label: 'Amount',
        value: 'CURRENTBALANCE',
    },
] as InputField[]

export const invoiceTypeDefaultValues = [
    {
        id: '1',
        label: 'Invoice',
        value: 'invoice',
    },
    {
        id: '2',
        label: 'Prorate',
        value: 'prorate',
    },
] as InputField[]

export const operatorValues = [
    {
        id: '1',
        label: 'Equals',
        value: 'equals',
    },
    {
        id: '2',
        label: 'Is Not Equal To',
        value: 'notequal',
    },
    {
        id: '3',
        label: 'Greater Than',
        value: 'greaterthan',
    },
    {
        id: '4',
        label: 'Less Than',
        value: 'lessthan',
    },
] as InputField[]

export const InvoiceStatusDefaultValues = [
    {
        id: '1',
        label: 'All',
        value: 'all',
    },
    {
        id: '2',
        label: 'Paid',
        value: 'closed',
    },
    {
        id: '3',
        label: 'Unpaid',
        value: 'open',
    },
] as InputField[]

export const statusDefaultValues = [
    // {
    //     id: '1',
    //     label: 'All',
    //     value: 'all',
    // },
    {
        id: '2',
        label: 'Ordered',
        value: 'installed',
    },
    {
        id: '3',
        label: 'Pending',
        value: 'pending',
    },
] as InputField[]

export const ToastMessageMap = {
    login: {
        success: {
            title: 'Welcome back!',
            description: 'Login Successful. Redirecting to Dashboard.',
        },
        invalidCred: {
            title: 'Invalid Credentials',
            description: 'Incorrect username or password.',
        },
    },
    userAdmin: {
        create: {
            success: {
                title: 'User Added!',
                description: 'The user has been successfully added.',
            },
            error: {
                title: 'Error Adding User!',
            },
        },
        remove: {
            success: {
                title: 'User Removed!',
                description: 'The user has been deleted.',
            },
            error: {
                title: 'Error Removing User!',
            },
        },
        update: {
            success: {
                title: 'Details Updated',
                description:
                    'The user details and permissions have been updated.',
            },
            error: {
                title: 'Error Updating User!',
            },
        },
    },
    placeOrder: {
        create: {
            title: 'Order Complete',
            description: 'Your order has been successfully placed.',
        },
        createFailed: {
            title: 'Order Failed',
            description:
                'Order failed. Please review your order details and try again and/or contact your account admin.',
        },
    },
}

export const getProductServiceLifeList = () => {
    const months = [12, 24, 36, 48, 60, 72, 84, 96, 108, 120]

    return months.reduce((acc: ProductServiceLifeType[], month, index) => {
        acc.push({
            id: `${index + 1}`,
            label: `${month / 12} Year (${month} Months)`,
            value: `${month}`,
        })
        return acc
    }, [])
}

export const getMonthsUsedList = () => {
    const monthArray: ProductServiceLifeType[] = []
    for (let i = 1; i <= 12; i++) {
        monthArray.push({
            id: `${i}`,
            label: `${i} Month`,
            value: `${i}`,
        })
    }
    return monthArray
}

export const getPercentageList = () => {
    const percentageArray: ProductServiceLifeType[] = []
    for (let i = 0; i <= 100; i++) {
        percentageArray.push({
            id: `${i}`,
            label: `${i}%`,
            value: `${i}`,
        })

        if (i >= 20) {
            i += 4
        }
    }
    return percentageArray
}

export const proRateInvoicePrintColumns: Parameters<
    typeof CustomDataTable<InvoiceProrateType>
>[0]['columns'] = [
    {
        name: 'Product / Description',
        selector: (row) => row.style,
        grow: 2,
    },
    {
        name: 'Color',
        selector: (row) => row.color,
    },
    {
        name: 'Product Life',
        selector: (row) => row.productLife,
        wrap: true,
    },
    {
        name: 'Used Life',
        selector: (row) => row.monthUsed,
        wrap: true,
    },
    {
        name: 'Lost Life',
        selector: (row) => row.lostLife,
        wrap: true,
    },
    {
        name: '% Increase',
        selector: (row) =>
            `${row.additionalAmount && row.style ? '' : `${row.increase}${row.increase && '%'}`}`,
        wrap: true,
    },
    {
        name: 'Amount',
        wrap: true,
        selector: (row) => {
            if (row.prorateAmount) {
                return (
                    Number(row.prorateAmount) *
                    (1 + Number(row.increase) / 100)
                ).toLocaleString('en-us', {
                    style: 'currency',
                    currency: 'USD',
                    minimumFractionDigits: 2,
                })
            } else {
                return Number(row.additionalAmount).toLocaleString('en-us', {
                    style: 'currency',
                    currency: 'USD',
                })
            }
        },
    },
]

export const WhitelistedPropertiesForOperators = [
    'orderNumber',
    'IVC_INVNO',
    'IVC_ORDNO',
    'R2_CBAL',
    'CURRENTBALANCE',
]
