import { getRouteApi, useNavigate } from '@tanstack/react-router'
import { Logo } from '../components/logo'

const router = getRouteApi('/$clientid')
export const OnboardingContainer = ({ children }: React.PropsWithChildren) => {
    const navigate = useNavigate({ from: '/' })
    const params = router.useParams()

    return (
        <div className="flex-1 relative flex flex-col items-center justify-center">
            <div className="absolute top-0 left-0 md:block hidden">
                <img
                    src="/svg/loginUpImg.svg"
                    alt=""
                    className="md:w-[17vw] w-[20vw]"
                />
            </div>
            <div className="mb-0 mt-8 max-w-64">
                <Logo
                    onClick={async () => {
                        await navigate({
                            to: '/$clientid/login',
                            params: params,
                        })
                    }}
                />
            </div>
            {children}
            <div className="absolute bottom-0 right-0 md:block hidden">
                <img
                    src="/svg/loginUpImg.svg"
                    alt=""
                    style={{
                        transform: 'scale(-1, -1)',
                    }}
                    className="md:w-[17vw] w-[20vw]"
                />
            </div>
        </div>
    )
}
