import { useToast } from '@broadlume/willow-ui'

export const useCustomToast = () => {
    const { toast } = useToast()
    const customToast = ({
        timeout,
        ...rest
    }: Parameters<typeof toast> & { timeout?: number }) => {
        console.log('toast', { timeout, ...rest })
        const newToast = toast({ ...rest })
        setTimeout(() => {
            newToast.dismiss()
        }, timeout || 3500)
    }
    return { toast: customToast }
}
