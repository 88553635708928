import { CustomDataTable } from '@broadlume/willow-ui'
import { addDays, format, isAfter, isBefore, isSameDay, parse } from 'date-fns'
import { toPng } from 'html-to-image'
import jsPDF from 'jspdf'
import _ from 'lodash'
import { DateRange } from './interfaces/orders.interface'
import {
    customInvoiceObject,
    GetInvoiceSearchAPIObject,
    GetOrdersOrdFileAPIObject,
    GetPendingOrderAPIObject,
    InvoiceProrateObject,
    InvoiceProrateType,
    OrderRow,
} from './interfaces/place-order.interface'

export const trimTrailingSpacesInArray = (
    array: Array<Record<string, string>>
): unknown => {
    return array.map((obj) => {
        Object.keys(obj).forEach((key) => {
            if (typeof obj[key] === 'string') {
                obj[key] = obj[key].trimEnd()
            }
        })
        return obj
    })
}

export const downloadPdf = async ({
    element,
    type,
}: {
    element: HTMLElement
    type?: string
}) => {
    try {
        let xOffset = 0
        let yOffset = 0
        const dataUrl = await toPng(element, {
            cacheBust: true,
            quality: 1,
        })

        const pdf = new jsPDF({
            orientation: 'portrait',
            unit: 'px',
        })

        const elementWidth = element.offsetWidth
        const elementHeight = element.offsetHeight

        const pageWidth = pdf.internal.pageSize.getWidth()
        const pageHeight = pdf.internal.pageSize.getHeight()

        const scaleFactor = Math.min(
            pageWidth / elementWidth,
            pageHeight / elementHeight
        )

        const scaledWidth = elementWidth * scaleFactor
        const scaledHeight = elementHeight * scaleFactor

        if (type === 'prorate') {
            xOffset = (pageWidth - scaledWidth) / 15
            yOffset = (pageHeight - scaledHeight) / 15
        }

        pdf.addImage(
            dataUrl,
            'PNG',
            xOffset,
            yOffset,
            scaledWidth,
            scaledHeight
        )
        return pdf
    } catch (err) {
        console.error('Error generating PDF:', err)
    }
}

export const groupByDeep = (collection: Array<unknown>, keys: string[]) => {
    return _.chain(collection)
        .map((item) => _.map(keys, (key) => _.get(item, key)))
        .reduce((result, paths, idx) => {
            const items = _.get(result, paths.join('.'), [])
            _.set(result, paths.join('.'), [...items, collection[idx]])
            return result
        }, {})
        .value()
}

export const generateTransactionID = (): string => {
    const year = new Date().getFullYear().toString().padStart(4, '0')
    const month = (new Date().getMonth() + 1).toString().padStart(2, '0')
    const day = new Date().getDate().toString().padStart(2, '0')
    const hour = new Date().getHours().toString().padStart(2, '0')
    const minute = new Date().getMinutes().toString().padStart(2, '0')
    const second = new Date().getSeconds().toString().padStart(2, '0')
    const random = Math.floor(Math.random() * 100)
        .toString()
        .padStart(2, '0')

    return year.slice(-2) + month + day + hour + minute + second + random
}

export const convertDateYYYYMMDD = (date: Date) => {
    if (!date) {
        return ''
    }
    const year = date.getFullYear()
    const month = String(date.getMonth() + 1).padStart(2, '0')
    const day = String(date.getDate()).padStart(2, '0')
    return `${year}${month}${day}`
}

export function addToRange(
    /** The date to add to the range. */
    date: Date,
    /** The range where to add `date`. */
    initialRange: DateRange | undefined
): DateRange | undefined {
    const { from, to } = initialRange || {}

    let range: DateRange | undefined

    if (!from && !to) {
        // the range is empty, add the date
        range = { from: date, to: date }
    } else if (from && !to) {
        // adding date to an incomplete range
        if (isSameDay(from, date)) {
            // adding a date equal to the start of the range
            range = undefined
        } else if (isBefore(date, from)) {
            // adding a date before the start of the range
            range = { from: date, to: from }
        } else {
            // adding a date after the start of the range
            range = { from, to: date }
        }
    } else if (from && to) {
        // adding date to a complete range
        if (isBefore(date, from)) {
            // adding a date before the start of the range
            range = { from: date, to: to }
        } else if (isAfter(date, from)) {
            // adding a date after the start of the range
            range = { from, to: date }
        } else if (isAfter(date, to)) {
            // adding a date after the end of the range
            range = { from, to: date }
        } else {
            range = { from: date, to: date }
        }
    }

    return range
}

/**
 * Converts a camelCase string to a title case string.
 * @param camelCase - A string in camelCase format.
 * @returns A string converted to title case.
 */
export const camelToTitle = (camelCase: string): string =>
    camelCase
        .replace(/([A-Z])/g, ' $1')
        .replace(/^./, (match) => match.toUpperCase())
        .trim()
        .replace('  ', ' ')

/**
 * Determines the checked state of a checkbox item within a list.
 *
 * @param {Object} params - The parameters for the function.
 * @param {Object} params.item - The checkbox item to evaluate.
 * @param {string} params.item.label - The label of the checkbox item.
 * @param {string} params.item.value - The value of the checkbox item.
 * @param {string[]} params.selectedArr - Array of selected item values.
 * @param {number} params.sourceArrLength - Total number of items in the source array.
 * @returns {boolean | string} - Returns `true` if all items are selected,
 * `false` if none are selected, 'indeterminate' if some are selected,
 * or checks if the specific item is included in the selected array.
 */
export const isCheckboxChecked = ({
    selectedArr = [],
    sourceArrLength,
    item,
}: {
    item: { label: string; value: string }
    selectedArr: string[]
    sourceArrLength: number
}) => {
    if (item.label.toLowerCase() === 'select all') {
        if (selectedArr.length) {
            if (selectedArr.length === sourceArrLength) {
                return true
            } else {
                return 'indeterminate'
            }
        } else {
            return false
        }
    }
    return selectedArr.includes(item.value)
}

export const orderAnalysisSortFn: Parameters<
    typeof CustomDataTable
>[0]['sortFunction'] = (rows, selector, direction) => {
    return rows.sort((rowA, rowB) => {
        // use the selector function to resolve your field names by passing the sort comparitors
        const aField = selector(rowA)
        const bField = selector(rowB)
        let key = Object.entries(rowA).find(
            ([
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                key,
                value,
            ]) => value === aField
        )?.[0]
        if (key === 'id') {
            key = rowA?.colSelector
        }
        if (
            rowA.id === 'totals' ||
            aField === '' ||
            bField === '' ||
            rowB.id === 'totals'
        ) {
            return 0
        }

        return allSortFn({
            sortBy: key,
            a: rowA,
            b: rowB,
            sortByDirection: direction === 'desc' ? '-1' : '1',
        })
    })
}

const sortByName = ({
    sortByDirection,
    a,
    b,
    data,
}: {
    sortByDirection: string
    a: OrderRow | customInvoiceObject
    b: OrderRow | customInvoiceObject
    data: keyof OrderRow | keyof customInvoiceObject
}) => {
    if (a[data] === '-' || b[data] === '-') {
        return 0
    }
    if (sortByDirection === '1' && a[data] > b[data]) {
        return 1
    } else {
        return -1
    }
}

export const allSortFn = ({
    sortBy = '',
    a,
    b,
    sortByDirection,
}: {
    sortBy: string
    a: OrderRow | customInvoiceObject
    b: OrderRow | customInvoiceObject
    sortByDirection: string
}) => {
    if (
        a[sortBy] === b[sortBy] ||
        ['', '-'].includes(a[sortBy]) ||
        ['', '-'].includes(b[sortBy])
    ) {
        return 0
    }
    switch (sortBy) {
        case '':
        case 'totals':
            return 0

        case 'orderNumber':
            return (Number(b.id) - Number(a.id)) * Number(sortByDirection)
        case 'invoiceNumber':
        case 'amount':
        case 'balanceDue':
        case 'poNumber':
        case 'invoiceOrderNumber':
        case 'building':
        case 'saleTotal':
        case 'saleQty':
            return (
                (Number(b[sortBy]) - Number(a[sortBy])) *
                Number(sortByDirection)
            )
        case 'status':
        case 'SLS_STYLE':
        case 'orderedBy':
        case 'DisplayName':
            return sortByDirection === '1'
                ? b[sortBy].localeCompare(a[sortBy], 'en', {
                      sensitivity: 'base',
                  })
                : a[sortBy].localeCompare(b[sortBy], 'en', {
                      sensitivity: 'base',
                  })

        case 'unit':
            return sortByDirection === '1'
                ? b[sortBy].localeCompare(a[sortBy], 'en', {
                      sensitivity: 'base',
                      numeric: true,
                  })
                : a[sortBy].localeCompare(b[sortBy], 'en', {
                      sensitivity: 'base',
                      numeric: true,
                  })

        case 'customerName':
            return sortByName({
                sortByDirection: sortByDirection,
                a: a,
                b: b,
                data: sortBy,
            })
        case 'locationCode':
            if (sortByDirection === '1' && a.orderedBy > b.orderedBy) {
                return 1
            } else {
                return -1
            }
        case 'buildingUnit':
            if (sortByDirection === '1' && a.orderedBy > b.orderedBy) {
                return 1
            } else {
                return -1
            }
        case 'invoiceDate':
        case 'dueDate':
        case 'orderDate':
        case 'installDate': {
            // Handle identical or empty date values
            if (
                a[sortBy] === b[sortBy] ||
                ['', '-'].includes(a[sortBy]) ||
                ['', '-'].includes(b[sortBy])
            ) {
                return 0
            }
            if (sortByDirection === '1' && a[sortBy] > b[sortBy]) {
                return 1
            } else {
                return -1
            }
        }
        default:
            return sortByDirection === '1'
                ? Number(a.id) - Number(b.id)
                : Number(b.id) - Number(a.id)
    }
}

/**
 * Sort an array by a single key with custom direction and handle special cases.
 * Moves empty/null/undefined values to the end or start based on direction.
 * @param array - The array to sort.
 * @param key - The key to sort by.
 * @param direction - The direction for sorting ('asc' or 'desc').
 * @returns The sorted array with empty values at the top or bottom.
 */
export const commonSort = <T>(
    array: T[],
    key: keyof T,
    direction: 'asc' | 'desc'
): T[] => {
    // Filter out elements with empty or invalid values
    const [validElements, emptyElements] = array.reduce(
        (acc, item) => {
            const value = _.get(item, key)

            if (
                value === null || // Null
                value === undefined || // Undefined
                (typeof value === 'string' && value.trim().length === 0) || // Empty strings
                (typeof value === 'number' && isNaN(value)) // NaN
            ) {
                acc[1].push(item) // Add to empty elements
            } else {
                acc[0].push(item) // Add to valid elements
            }
            return acc
        },
        [[], []] as [T[], T[]]
    )

    // Sort valid elements
    const sortedValidElements = _.orderBy(
        validElements,
        [
            (item: T) => {
                const value = _.get(item, key)

                // Handle numeric fields directly
                if (typeof value === 'number') {
                    return value
                }

                // Handle date fields directly
                if (typeof value === 'object' && value instanceof Date) {
                    return value.getTime() // Sort by timestamp
                }

                if (typeof value === 'string') {
                    // Handle numeric strings by converting to numbers
                    if (!isNaN(Number(value))) {
                        return Number(value)
                    }

                    // Handle date strings
                    const dateValue = new Date(value)
                    if (!isNaN(dateValue.getTime())) {
                        return dateValue.getTime() // Sort by timestamp
                    }

                    // Handle simple names (alphabetical sorting)
                    return value.trim().toLowerCase()
                }

                // Move random gibberish to top or bottom
                return direction === 'asc'
                    ? Number.POSITIVE_INFINITY
                    : Number.NEGATIVE_INFINITY
            },
        ],
        [direction]
    )

    /**
     * Custom comparator function for sorting objects based on a specified key.
     *
     * @param a - The first object to compare.
     * @param b - The second object to compare.
     * @returns A number indicating the sort order. Returns a positive number if `a` should be sorted after `b`,
     *          a negative number if `a` should be sorted before `b`, or 0 if they are considered equal.
     *
     * The function uses `_.get` to safely access the values of the specified key from both objects.
     * If the key is 'poNumber', it performs a locale-aware string comparison with numeric sensitivity.
     * The sort direction is determined by the `direction` variable, which can be 'asc' for ascending
     * or 'desc' for descending order.
     */
    const customComparator = (a: T, b: T) => {
        const valueA = _.get(a, key)
        const valueB = _.get(b, key)

        switch (key) {
            case 'orderNumber':
            case 'poNumber':
            case 'building':
            case 'unit':
                // do localCompare for string values
                return direction === 'asc'
                    ? valueA.localeCompare(valueB, 'en', { numeric: true })
                    : valueB.localeCompare(valueA, 'en', { numeric: true })

            default:
                // return direction === 'asc' ? -1 : 1
                return 0
        }
    }

    // Concatenate empty elements at the end or beginning based on direction
    return direction === 'desc'
        ? [...sortedValidElements.sort(customComparator), ...emptyElements]
        : [...emptyElements, ...sortedValidElements.sort(customComparator)]
}

export const getPendingOrderDataTableRow = (
    order: GetPendingOrderAPIObject
) => {
    return {
        id: order.PODMO_TRANSACTION,
        poNumber: order.PODMO_CUST_PO,
        orderDate: convertToDate({
            value: order.PODMO_DATE,
            format: 'yyyyMMdd',
        }),
        installDate: convertToDate({
            value: order.PONOTE_DATE,
            format: 'yyyyMMdd',
        }),
        customerName: order.CADD_NAME,
        shipToAddress: order.PODMO_INSTALL_ADDR,
        billToAddress: order.C_ADDR1,
        unit: order.PODMO_UNIT,
        orderNumber: order.PODMO_TRANSACTION,
        addresses: `${order.PODMO_INSTALL_ADDR} ${order.PODMO_BUILDING} #${order.PODMO_UNIT}`,
        building: order.PODMO_BUILDING,
        orderedBy: order.PODMO_WEB_USER,
        status: 'Pending',
        action: '',
    }
}

export const getInvoiceDataTableRow = (
    invoice: GetInvoiceSearchAPIObject
): customInvoiceObject => {
    let termsDay = parseInt(invoice.R2_TNET)
    if (
        parseInt(invoice.R2_TNET) === 0 &&
        parseInt(invoice.R2_TDAYS) === 0 &&
        parseInt(invoice.R2_TERMS) === 0
    ) {
        termsDay = 30
    } else if (
        parseInt(invoice.R2_TDAYS) !== 0 &&
        parseInt(invoice.R2_TERMS) !== 0
    ) {
        termsDay = parseInt(invoice.R2_TDAYS)
    }
    return {
        id: invoice.IVC_INVNO,
        invoiceNumber: invoice.IVC_INVNO,
        invoiceOrderNumber: invoice.IVC_ORDNO,
        poNumber: invoice.IVC_CUSTPO,
        invoiceDate: convertToDate({
            value: invoice.IVC_DATE,
            format: 'MMddyyyy',
        }),

        installDate: convertToDate({
            value: invoice.IVC_INSTALLDATE,
            format: 'MMddyyyy',
        }),
        dueDate:
            invoice.IVC_INSTALLDATE === '00000000'
                ? ''
                : new Date(
                      format(
                          addDays(
                              parse(
                                  invoice.IVC_INSTALLDATE,
                                  'MMddyyyy',
                                  new Date()
                              ),
                              termsDay
                          ),
                          'MM/dd/yyyy'
                      )
                  ),
        address1: invoice.DMH_SHADDR1.trim(),
        building: invoice?.BLDG_ONLY?.trim(),
        orderedBy: invoice.DMH_ORDEREDBY ? invoice.DMH_ORDEREDBY : '-',
        unit: invoice?.UNIT_ONLY,
        propType: invoice.PROPTYPE,

        amount: parseFloat(invoice.CURRENTBALANCE) / 100,

        balanceDue: parseFloat(invoice.R2_CBAL) / 100,
        // installDate: readableDate(order.PONOTE_DATE),
        // customerName: order.CADD_NAME,
        // addresses: `${order.CADD_ADDR1}
        // #${order.PODMO_UNIT}
        // ${order.PODMO_INSTALL_ADDR}`,
        // orderBy: order.PODMO_WEB_USER,
        // status: 'Pending',
        // action: '',
    }
}

export const getInvoiceProrateDataTable = (
    invoiceProrate: InvoiceProrateObject
): InvoiceProrateType => {
    return {
        id: Number(invoiceProrate.PLN_LNSEQ),
        style: invoiceProrate.PLT_PD_ST,
        color: invoiceProrate.PLT_CO_TY,
        invoiceAmount: (parseFloat(invoiceProrate.PLT_EXT) / 100).toFixed(2),
        productLife: '-',
        monthUsed: '-',
        lostLife: '-',
        prorateAmount: '-',
        type: invoiceProrate.PLN_TYPE,
        buildingUnit: invoiceProrate.DMH_SHADDR2,
        property: invoiceProrate.DMH_SHNAME,
        propertyAddress: `${invoiceProrate.S_NAME}\n ${invoiceProrate.S_ADDR1}\n ${invoiceProrate.S_ADDR2 || ''}\n ${invoiceProrate.S_CTYST}\n ${invoiceProrate.S_ZIPCD}`,
    }
}

export const getOrderDataTableRow = (order: GetOrdersOrdFileAPIObject) => {
    return {
        id: order.DMO_ORDNO,
        orderDate: convertToDate({ value: order.DMH_DATE, format: 'MMddyyyy' }),
        customerName: order.CADD_NAME,
        addresses: `${order.DMH_SHADDR1} ${order.BLDG} #${order.UNIT}`,
        shipToAddress: order.DMH_SHADDR1,
        billToAddress: order.C_ADDR1,
        installDate: convertToDate({
            value: order.DMH_INSTALLDATE,
            format: 'MMddyyyy',
        }),
        building: order.BLDG,
        unit: order.UNIT,
        orderedBy: order.DMH_ORDEREDBY,
        action: '',
        orderNumber: order.DMO_ORDNO,
        poNumber: order.DMH_CUSTPO,
        invoiceDate: convertToDate({
            value: order.DMH_DATE,
            format: 'MMddyyyy',
        }),
        dueDate: convertToDate({
            value: order.DMH_INSTALLDATE,
            format: 'MMddyyyy',
        }),
    }
}

export const convertToDate = ({
    value,
    format = 'yyyyMMdd',
}: {
    value?: string
    format?: string
}) => {
    return !value || value === '00000000'
        ? ''
        : parse(value, format, new Date())
}

export const getSortField = (sortField: string) => {
    switch (sortField) {
        case 'invoiceOrderNumber':
        case 'invoiceNumber':
        case 'poNumber':
            return 'invoiceNumber:invoiceOrderNumber:poNumber'
        case 'invoiceDate':
        case 'installDate':
        case 'dueDate':
            return 'invoiceDate:installDate:dueDate'
        case 'address1':
        case 'building':
        case 'unit':
            return 'address1:building:unit'
        case 'orderedBy':
            return 'orderedBy'
        case 'amount':
            return 'amount'
        case 'balanceDue':
            return 'balanceDue'
        default:
            return ''
    }
}

export const fileToBase64 = (file: File): Promise<string> => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.readAsDataURL(file) // Converts file to a Base64 string
        reader.onload = () => resolve(reader.result as string)
        reader.onerror = (error) => reject(error)
    })
}

// Helper functions to calculate luminance and contrast ratio
export const getLuminance = (hexColor: string) => {
    const rgb = hexToRgb(hexColor)
    const [r, g, b] = rgb.map((value) => {
        const channel = value / 255
        return channel <= 0.03928
            ? channel / 12.92
            : Math.pow((channel + 0.055) / 1.055, 2.4)
    })
    return 0.2126 * r + 0.7152 * g + 0.0722 * b
}

export const getContrastRatio = (bgColor: string, textColor: string) => {
    const bgLuminance = getLuminance(bgColor)
    const textLuminance = getLuminance(textColor)
    return (
        (Math.max(bgLuminance, textLuminance) + 0.05) /
        (Math.min(bgLuminance, textLuminance) + 0.05)
    )
}

export const hexToRgb = (hex: string) => {
    const normalizedHex = hex.replace('#', '')
    const bigint = parseInt(normalizedHex, 16)
    return [bigint >> 16, (bigint >> 8) & 255, bigint & 255]
}

export const isReadable = (bgColor: string, textColor: string) => {
    const contrastRatio = getContrastRatio(bgColor, textColor)
    console.log('contrastRatio', contrastRatio, bgColor, textColor)
    return contrastRatio >= 4.5
}

export const getReadableFontColor = (bgColor: string, textColor: string) => {
    return isReadable(bgColor, textColor)
        ? textColor
        : getContrastRatio(bgColor, '#000000') >
            getContrastRatio(bgColor, '#ffffff')
          ? '#000000'
          : '#ffffff'
}
