import { useQuery } from '@tanstack/react-query'
import { GetCustomerAPIResopnse } from '../interfaces/place-order.interface'
import { useAuthHook } from './order-analysis.hook'
import { getCustomer } from '../../services/placeOrder'
import { useGetPordFile } from './podfile.hook'

export const useGetCustomerPordFile = () => {
    const { data: userData } = useAuthHook()
    const {
        data: pordFileData,
        isLoading: isPordFileLoading,
        refetch: refetchPordFileData,
    } = useGetPordFile()
    const { data: customerData, isLoading: isCustomerLoading } =
        useQuery<GetCustomerAPIResopnse>({
            queryKey: ['getCustomer', userData],
            queryFn: async () => {
                const customers = await getCustomer()
                const pordFile = await refetchPordFileData()
                const filteredCustomers: GetCustomerAPIResopnse = []
                customers.map((customer) => {
                    let count = 0
                    pordFile.data?.map((eachPordFile) => {
                        if (customer.C_CID === eachPordFile.PDMO_CUSTID) {
                            count++
                        }
                    })
                    if (count > 0) {
                        filteredCustomers.push(customer)
                    }
                })

                return filteredCustomers
            },
            staleTime: Infinity,
        })

    return { customerData, isCustomerLoading, isPordFileLoading, pordFileData }
}
