import { Button } from '@broadlume/willow-ui'
import { PrimaryButton } from './primary-button'
import clsx from 'classnames'

const SecondaryButton: React.FC<Parameters<typeof PrimaryButton>[0]> = ({
    title,
    children,
    className = '',
    ...rest
}) => (
    <Button
        {...rest}
        variant="outline"
        type="button"
        className={clsx(
            'text-xs',
            rest.disabled ? 'opacity-60' : '',
            'hover:opacity-85',
            'border bg-custom-secondary_button_background hover:bg-custom-secondary_button_background custom-secondary-button',
            className || ''
        )}
    >
        {title ?? children}
    </Button>
)

export { SecondaryButton }
