import { Toaster } from '@broadlume/willow-ui'
import { QueryClient } from '@tanstack/react-query'
import { createRootRouteWithContext, Outlet } from '@tanstack/react-router'
import { Helmet, HelmetProvider } from 'react-helmet-async'
import Footer from '../components/footer'
import { useGetClient } from '../utils/hooks/order-analysis.hook'

// const ENV = import.meta.env.VITE_API_ENV
// const ORIGIN = import.meta.env.VITE_ORIGIN

export const Route = createRootRouteWithContext<{
    queryClient: QueryClient
}>()({
    component: () => {
        return (
            <div className="flex flex-1 flex-col min-h-screen">
                <CustomHelmet />

                <div className="flex flex-1">
                    <Outlet />
                </div>

                <Footer />
                <Toaster />
            </div>
        )
    },
    notFoundComponent() {
        return (
            <div>
                Client Id not found. Please add client id in the url or use the
                url provided during onboarding.
            </div>
        )
    },
})

const CustomHelmet = () => {
    const { data: clientId } = useGetClient()
    const CLIENT_CUSTOM_CSS_URL = `https://dev.customerportal.bms.my.broadlume.com/${clientId}/css/custom-theme.css`
    // const CLIENT_CUSTOM_CSS_URL = ``
    const CLIENT_CUSTOM_LOGO = `https://dev.customerportal.bms.my.broadlume.com/${clientId}/data/images/logo.png`

    return (
        <HelmetProvider>
            <Helmet>
                {/* <style>{url}</style> */}
                <link
                    rel="icon"
                    type="image/svg+xml"
                    href={CLIENT_CUSTOM_LOGO}
                />
                <link href={CLIENT_CUSTOM_CSS_URL} rel="stylesheet"></link>
            </Helmet>
        </HelmetProvider>
    )
}
