import React, { useState, useEffect } from 'react'

import { FaChevronDown, FaChevronRight } from 'react-icons/fa'
import { Checkbox, Label } from '@broadlume/willow-ui'
import clsx from 'classnames'

export type TreeNode = {
    id: string
    name: string
    value: string
    children?: TreeNode[]
}

type TreeViewProps = {
    data: TreeNode[]
    onChange?: (selectedValues: string[]) => void
    selectedValues?: string[]
}

type CheckedState = Record<string, { checked: boolean; indeterminate: boolean }>

const TreeView: React.FC<TreeViewProps> = ({
    data,
    onChange,
    selectedValues,
}) => {
    const [checkedState, setCheckedState] = useState<
        Record<string, { checked: boolean; indeterminate: boolean }>
    >({})
    const [expandedState, setExpandedState] = useState<Record<string, boolean>>(
        {}
    ) // Track expanded state

    useEffect(() => {
        if (selectedValues && selectedValues.length > 0) {
            const updatedCheckedState = initializeCheckedState(
                data,
                selectedValues
            )
            setCheckedState(updatedCheckedState)
        } else {
            const initializeState = (nodes: TreeNode[]): CheckedState => {
                const state: CheckedState = {}
                const traverse = (node: TreeNode) => {
                    state[node.id] = { checked: false, indeterminate: false }
                    if (node.children) {
                        node.children.forEach(traverse)
                    }
                }
                nodes.forEach(traverse)
                return state
            }

            const initializeExpandedState = (
                nodes: TreeNode[]
            ): Record<string, boolean> => {
                const state: Record<string, boolean> = {}
                const traverse = (node: TreeNode) => {
                    state[node.id] = true // Default to expanded
                    if (node.children) {
                        node.children.forEach(traverse)
                    }
                }
                nodes.forEach(traverse)
                return state
            }

            setCheckedState(initializeState(data))
            setExpandedState(initializeExpandedState(data))
        }
    }, [selectedValues, data])

    const initializeCheckedState = (
        nodes: TreeNode[],
        selectedValues: string[]
    ): CheckedState => {
        const state: CheckedState = {}

        const traverse = (node: TreeNode) => {
            if (node.children && node.children.length > 0) {
                // Recursively initialize children's state
                node.children.forEach(traverse)

                // Determine the parent's checked state based on its children
                const allChildrenChecked = node.children.every(
                    (child) => state[child.id]?.checked
                )
                const someChildrenChecked = node.children.some(
                    (child) =>
                        state[child.id]?.checked ||
                        state[child.id]?.indeterminate
                )

                state[node.id] = {
                    checked: allChildrenChecked,
                    indeterminate: !allChildrenChecked && someChildrenChecked,
                }
            } else {
                // For leaf nodes, set checked based on `selectedValues`
                state[node.id] = {
                    checked: selectedValues.includes(node.value),
                    indeterminate: false,
                }
            }
        }

        nodes.forEach(traverse)
        return state
    }

    const handleCheckboxChange = (id: string) => {
        const updatedCheckedState = { ...checkedState }
        const isChecked = !checkedState[id]?.checked

        const updateChildren = (nodeId: string, checked: boolean) => {
            updatedCheckedState[nodeId] = { checked, indeterminate: false }
            const node = findNodeById(data, nodeId)
            if (node?.children) {
                node.children.forEach((child) =>
                    updateChildren(child.id, checked)
                )
            }
        }

        const updateParents = (nodeId: string) => {
            const parentNode = findParentNode(data, nodeId)
            if (parentNode) {
                const allChildren = parentNode.children || []
                const allChecked = allChildren.every(
                    (child) => updatedCheckedState[child.id]?.checked
                )
                const someChecked = allChildren.some(
                    (child) =>
                        updatedCheckedState[child.id]?.checked ||
                        updatedCheckedState[child.id]?.indeterminate
                )

                updatedCheckedState[parentNode.id] = {
                    checked: allChecked,
                    indeterminate: !allChecked && someChecked,
                }
                updateParents(parentNode.id)
            }
        }

        updateChildren(id, isChecked)
        updateParents(id)
        setCheckedState(updatedCheckedState)

        // Calculate selected values and pass to parent
        const selectedValues = getSelectedChildrenValues(
            data,
            updatedCheckedState
        )
        onChange && onChange(selectedValues)
    }

    const getSelectedChildrenValues = (
        nodes: TreeNode[],
        checkedState: {
            [x: string]: {
                checked: boolean
                indeterminate: boolean
            }
        }
    ): string[] => {
        let selectedValues: string[] = []
        nodes.forEach((node) => {
            if (node.children && node.children.length > 0) {
                selectedValues = selectedValues.concat(
                    getSelectedChildrenValues(node.children, checkedState)
                )
            } else if (checkedState[node.id]?.checked) {
                selectedValues.push(node.value)
            }
        })
        return selectedValues
    }

    const findParentNode = (
        nodes: TreeNode[],
        id: string,
        parent: TreeNode | null = null
    ): TreeNode | null => {
        for (const node of nodes) {
            if (node.id === id) return parent
            if (node.children) {
                const parentNode = findParentNode(node.children, id, node)
                if (parentNode) return parentNode
            }
        }
        return null
    }

    const findNodeById = (nodes: TreeNode[], id: string): TreeNode | null => {
        for (const node of nodes) {
            if (node.id === id) return node
            if (node.children) {
                const found = findNodeById(node.children, id)
                if (found) return found
            }
        }
        return null
    }

    // const findParentById = (
    //     nodes: TreeNode[],
    //     id: string,
    //     parent: TreeNode | null
    // ): TreeNode | null => {
    //     for (const node of nodes) {
    //         if (node.id === id) return parent
    //         if (node.children) {
    //             const found = findParentById(node.children, id, node)
    //             if (found) return found
    //         }
    //     }
    //     return null
    // }

    const toggleExpand = (id: string) => {
        setExpandedState((prevState) => ({
            ...prevState,
            [id]: !prevState[id],
        }))
    }

    const renderTree = (nodes: TreeNode[]) => (
        <ul className="ml-4">
            {nodes.map((node) => (
                <li key={node.id} data-testid={'node-li-' + node.id}>
                    <div
                        className={clsx('flex items-center gap-2 mt-3 mb-2', {
                            'mt-6': node.children && node.children.length > 0,
                        })}
                    >
                        {/* Space reserved for Chevron, even if the node doesn't have one */}
                        <span className="w-4 flex-shrink-0">
                            {node.children && node.children.length > 0 && (
                                <span
                                    onClick={() => toggleExpand(node.id)}
                                    className="cursor-pointer"
                                >
                                    {expandedState[node.id] ? (
                                        <FaChevronDown />
                                    ) : (
                                        <FaChevronRight />
                                    )}
                                </span>
                            )}
                        </span>

                        <Checkbox
                            data-testid={'checkbox-' + node.id}
                            {...(checkedState[node.id]?.indeterminate
                                ? { checked: 'indeterminate' }
                                : checkedState[node.id]?.checked
                                  ? { checked: true }
                                  : { checked: false })}
                            onClick={() => handleCheckboxChange(node.id)}
                        />

                        <Label data-testid={'label-' + node.id}>
                            {node.name}
                        </Label>
                    </div>
                    {/* Children are indented */}
                    {node.children &&
                        expandedState[node.id] &&
                        renderTree(node.children)}
                </li>
            ))}
        </ul>
    )

    return <div>{renderTree(data)}</div>
}

export { TreeView }
